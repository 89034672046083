<template>
  <div class="product-card-details fade-in-top" v-if="product">
      <div class="product-images">
            <div class="product-img">
                <img :src="product.imgs[imgIdx].imgSrc" :alt="product.imgs[imgIdx].imgName">
            </div>
            <div class="product-imgs-array" v-if="product.imgs.length > 0">
                <div v-for="(img, idx) in product.imgs" :key="idx" @click="changeImgThumbnail(idx, product.id)">
                    <img :style="[img.visibleThumbnail ? {opacity: '1'} : {opacity: '.5'}]" :src="img.imgSrc" :alt="img.imgName">
                </div>
            </div>
      </div>

      <div class="product-info">
            <h2>{{product.name}}</h2>
            <div v-html="product.description"></div>
      </div>

  </div>
</template>

<script>
export default {
    data () {
        return {
            imgIdx: 0,
            productId: this.$route.params.id,
            product: null
        }
    },
    methods: {
        changeImgThumbnail (idx, prodId) {
            this.imgIdx = idx;
            let obj = {
                id: prodId,
                idx: idx
            };
            this.$store.commit("setImageThumbnail", obj);
        }
    },
    mounted () {
        this.product = this.$store.getters.getProductById(this.productId)
    },
    unmounted () {
        this.$store.commit("setDefaultThumbnail", {id: this.productId})
    },
    created() {
        this.$watch(
        () => this.$route.params,
            (toParams) => {
                this.product = this.$store.getters.getProductById(toParams.id);
            }
        )
    },
}
</script>

<style lang="scss" scoped>
    .product-card-details{
        margin: 100px auto 30px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        transition: .3s;
        div {
            width: 45%;
        }
        .product-images {
            .product-img {
                width: 90%;
                height: 400px;
                display: flex;
                justify-content: center;
                align-content: center;
                border: 2px solid #7395ae96;
            }
            .product-imgs-array {
                width: 90%;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                width: 100%;
                div {
                    width: 70px;
                    height: 70px;
                    border: 2px solid #7395ae96;
                    margin: 5px 5px 5px 0;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    transition: .3s;
                    &:hover {
                        opacity: 1;
                        cursor: pointer;
                    }
                }
            }
        }
        .product-info {
            padding: 25px 40px;
            border-top: 2px solid #7395ae96;
            border-bottom: 2px solid #7395ae96;
            text-align: left;
            div {
                width: 95%;
            }            
            p{
            text-align: left;
            // color: #333;
            }
        }
    }
    @media screen and (max-width: 850px) {
        .product-card-details{
            div {
                width: 95%;
            }
            
            .product-images {
                .product-img {
                    width: 100%;
                    height: 300px;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    border: 2px solid #7395ae96;
                }
                .product-imgs-array {
                    width: 100%;
                }
            }    
        }
    }
    
</style>
